import * as React from "react"
import Layout from "../../components/layout"
import Services from "../../components/services"
import SEO from "../../components/seo"

const ServicesPage = () => (
  <Layout>
    <SEO
      title="Services"
      description="We cater specifically for lifestyle block animals. We understand that every lifestyle farm is different and the challenges that may represent."
    />
    <section className="pb-0">
      <div className="container">
        <h1 className="section-heading mb-0">Services</h1>
        <Services />
      </div>
    </section>
  </Layout>
)

export default ServicesPage
